@import "../../assets/vendors/material-design-icon/css/material-design-iconic-font.min.css";

/* SLDFLKA */
:root {
  --light: Muna, Gilroy-Light;
  --regular: Muna, Gilroy-Regular;
  --medium: Muna, Gilroy-Medium;
  --bold: Muna-Bold, Gilroy-Bold;
  --extra-bold: Muna-Bold, Gilroy-ExtraBold;
  font-weight: normal;
  font-style: normal;
}

body {
  line-height: 1.35 !important;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

/*
  react-phone-input styles
*/

.react-tel-input .form-control {
  width: 100% !important;
}

.react-phone-input-2-container li.search {
  padding: 10px !important;
}

.react-phone-input-2-container .special-label {
  color: rgba(0, 0, 0, 0.67);
}
.react-phone-input-2-container li.search .search-box {
  padding: 15px;
  margin: 0;
}

.react-phone-input-2-dropdown-class::-webkit-scrollbar {
  width: 5px !important;
}

/* RTL */

.react-tel-input .form-control {
  padding-right: 55px !important;
}

.react-tel-input .selected-flag .arrow {
  right: 30px;
}
body[dir="rtl"] .react-tel-input .selected-flag {
  padding-right: 0;
}

body[dir="rtl"] .flag {
  margin-right: 11px;
}
/*
  profile pic
*/

.profile-picture__modal__file-input {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 16px;
  border: 2px dashed #eee;
  cursor: pointer;
  background-color: #f6f6fa;
  border-radius: 12px;
  min-height: 150px;
}
.profile-picture__modal__file-input:active,
.profile-picture__modal__file-input:focus {
  outline: none;
  border-color: green;
}

/*
  modernDatePicker
*/

.DatePicker {
  width: 100%;
}
.DatePicker__input {
  font-size: 16px !important;
  border: 1px solid #cacaca !important;
  border-radius: 5px;
  width: 100%;
  text-align: left !important;
  outline: none;
  padding: 18.5px 0px 18.5px 15px !important;
}

.DatePicker__input:hover {
  border-color: black !important;
}

/* 
 */

.react-tel-input {
  direction: ltr !important;
}
